export const OK = "ok";
export const NETWORK_ERROR = "NETWORK_ERROR";

export enum StatusCode {
  OK_200 = 200,
  BAD_REQUEST_400 = 400,
  UNAUTHORIZED_401 = 401,
  FORBIDDEN_403 = 403,
  NOT_FOUND_404 = 404,
  SERVER_ERROR_500 = 500,
}

export enum LocalStorageKey {
  /** Api-токен */
  API_TOKEN = "hhr-token",
}

/** URLs api HHR */
export enum ApiPath {
  /** Аутентификация пользователя в приложении */
  USER_LOGIN = "/api/user.login",
  /** Выход пользователя из приложения */
  USER_LOGOUT = "/api/user.logout",
  /** Обновить access и refresh токены */
  USER_REFRESH = "/api/user.refresh",
  /** Получить информацию о пользователе */
  USER_CURRENT = "/api/user.current",

  /** Получить настройки приложения */
  CLIENT_ITEM = "/api/client.item",

  /** Получить список корневых табов */
  INTERFACE_TAB_ROOT = "/api/interface.tab.root.list",
  /** Получить дерево табов */
  INTERFACE_TAB_TREE = "/api/interface.tab.tree",
}
